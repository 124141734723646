//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './SettingsEmailFilters.vue';
import SendEmailModal from './SendEmailModal.vue';
import EmailOptionsModal from './EmailOptionsModal.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, SendEmailModal, EmailOptionsModal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      loadingHistory: false,
      model: {
        subject: '',
        body: '',
      },
      currentEmailMessageType: 0,
      updateLoader: false,
      previewTemplateState: false,
      constant,
      sendEmailState: false,
      emailOptionsModal: {
        show: false,
        data: {},
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('settingsEmail', [
      'page',
      'pages',
      'data',
      'sort',
      'emailMessageTemplateHistory',
      'filters',
    ]),

    currentEmailMessage() {
      const { data, currentEmailMessageType } = this;
      return data.find((e) => e.emailMessageType === currentEmailMessageType);
    },

    currentEmailMessageId() {
      const { currentEmailMessage } = this;
      if (!currentEmailMessage) return 0;
      return currentEmailMessage.id;
    },

    emailMessageTemplateHistoryWithCurrent() {
      const { emailMessageTemplateHistory, currentEmailMessage } = this;
      return [
        currentEmailMessage,
        ...emailMessageTemplateHistory,
      ];
    },

    isNoChanges() {
      const { model, currentEmailMessage } = this;
      if (!currentEmailMessage) return false;
      return (
        model.body === currentEmailMessage.body
        && model.subject === currentEmailMessage.subject
      );
    },

    showHTMLPreview() {
      return this.filters.emailBodyType === 0;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('settingsEmail', {
      loadDataAction: 'loadData',
      updateEmailMessageTemplateAction: 'updateEmailMessageTemplate',
      addEmailMessageTemplateAction: 'addEmailMessageTemplate',
      loadEmailMessageTemplateHistoryAction: 'loadEmailMessageTemplateHistory',
      getEmailOptionsAction: 'getEmailOptions',
      updateEmailOptionsAction: 'updateEmailOptions',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);

      const { currentEmailMessageType } = this;
      this.setEmailMessageType(currentEmailMessageType);

      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async setEmailMessageTypeHandler(emailMessageType) {
      this.loadingHistory = true;
      this.setGeneralProgress(true);
      await this.setEmailMessageType(emailMessageType);
      this.loadingHistory = false;
      this.setGeneralProgress(false);
    },

    async setEmailMessageType(emailMessageType) {
      this.currentEmailMessageType = emailMessageType;
      const { currentEmailMessage } = this;
      const { emailBodyType, language } = this.filters;
      if (currentEmailMessage) {
        this.model.subject = currentEmailMessage.subject;
        this.model.body = currentEmailMessage.body;
        await this.loadEmailMessageTemplateHistoryAction({
          emailMessageType,
          emailBodyType,
          language,
        });
      }
    },

    async saveTemplate() {
      const { language, emailMessageType, emailBodyType } = this.currentEmailMessage;
      const { subject, body } = this.model;
      const payload = {
        language,
        emailMessageType,
        emailBodyType,
        subject,
        body,
      };
      const { currentEmailMessageType } = this;

      this.setGeneralProgress(true);
      this.updateLoader = true;
      try {
        await this.addEmailMessageTemplateAction(payload);
        this.loader = true;
        await this.loadDataAction({});
        this.setEmailMessageType(currentEmailMessageType);
        this.loader = false;
        this.setSuccessNotification('Email template updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.updateLoader = false;
    },

    cancelTemplate() {
      const { currentEmailMessage } = this;
      if (currentEmailMessage) {
        this.model.subject = currentEmailMessage.subject;
        this.model.body = currentEmailMessage.body;
      }
    },

    setEmailMessageTypeFromHistoryHandler(value) {
      const { emailMessageTemplateHistoryWithCurrent } = this;
      const selected = emailMessageTemplateHistoryWithCurrent.find((e) => e.id === value);
      this.model.subject = selected.subject;
      this.model.body = selected.body;
    },

    historyTextFunction(e) {
      return this.$utils.getFullDate(e.dateCreated);
    },

    previewTemplate() {
      this.previewTemplateState = !this.previewTemplateState;
    },

    closeSendEmail() {
      this.sendEmailState = false;
    },

    async openEmailOptionsModal() {
      this.setGeneralProgress(true);
      this.emailOptionsModal.data = await this.getEmailOptionsAction();
      this.emailOptionsModal.show = true;
      this.setGeneralProgress(false);
    },

    updateEmailOptions(data) {
      this.confirmAction({
        title: 'Are you sure you want to change Email options?',
        callback: async () => {
          this.emailOptionsModal.show = false;
          this.setGeneralProgress(true);
          try {
            this.updateEmailOptionsAction(data);
            this.setSuccessNotification('Email options changed');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
